import React, { useCallback, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import Media from '../../../../../store/types/media';
import ContentLibraryFilters, { ContentLibraryFilter } from './content-library-filters.component';
import ContentLibraryMediaList from './content-library-media-list.component';
import ContentLibraryMediaPreview from './content-library-media-preview.component';
import { MediaItemKind, getMediaItemKind } from '../../../../../utils/media-item-utils';
import useContentLibraryMediaList from '../../../../../store/hooks/content-library/use-content-library-media-list';
import { ButtonType } from '../../../../../types';
import ContentLibraryFolderHierarchy from './content-library-folder-hierarchy.component';
import ContentLibraryUploadFolder from './content-library-upload-folder.component';
import ContentLibraryUploadFile from './content-library-upload-file.component';
import { ContentUploadMediaStatusEnum } from '../../../../../store/hooks/content-library/use-content-library-upload-media';
import { extractFilesFromDragDrop, isAppsContentTypeSelected } from './library-utils';
import ContentLibraryFreeImage from './content-library-free-image.component';
import ModalFixedHeight from '../../../../common/modal-fixed-height.component';
import { PaginationCollection } from '../../../../../store/types/pagination';
import ContentLibraryAppList from './content-library-app-list.component';
import Gridapp from '../../../../../store/types/gridapp';
import ContentLibraryAppPreview from './content-library-app-preview.component';
import GridappBuild from '../../../../../store/types/gridapp-build';

export interface ContentLibraryProps {
  titleForPreviewModal: string;
  tenantId: string;
  selectedFolderId?: string;
  mediaSelectText?: string;
  disableAppSettingsForm?: boolean;
  onFolderSelect?: (folderId: string | undefined) => void;
  onMediaSelect?: (mediaItem: Media) => void;
  onAppSelect?: (gridApp: Gridapp, gridAppBuild: GridappBuild, appSettingsData: Record<string, string | number | boolean | Record<string, string>>) => void;
}

const EMPTY_PAGINATION_COLLECTION = {
  docs: [],
  totalDocs: 0,
  page: 0,
  limit: 0,
  hasPrevPage: false,
  hasNextPage: false,
  totalPages: 0,
  prevPage: 0,
  nextPage: 0,
}

const EMPTY_MEDIA_PAGINATION_COLLECTION: PaginationCollection<Media> = {
  ...EMPTY_PAGINATION_COLLECTION,
}

const EMPTY_APPS_PAGINATION_COLLECTION: PaginationCollection<Gridapp> = {
  ...EMPTY_PAGINATION_COLLECTION,
}

const ContentLibrary = (props: ContentLibraryProps) => {
  const {
    titleForPreviewModal,
    tenantId,
    selectedFolderId,
    mediaSelectText,
    disableAppSettingsForm,
    onFolderSelect,
    onMediaSelect,
    onAppSelect,
  } = props;

  const { t } = useTranslation();

  const [selectedMedia, updateSelectedMedia] = useState<Media | undefined>(undefined);
  const [selectedApp, updateSelectedApp] = useState<Gridapp | undefined>(undefined);
  const [filters, setFilters] = useState<ContentLibraryFilter>({});
  const [isNewFolderUploadModalVisible, setIsNewFolderUploadModalVisible] = useState(false);
  const [newFiles, setNewFiles] = useState<File[]>([]);
  const [isStockImageUploadVisible, setIsStockImageUploadVisible] = useState(false);
  const [isDragOverActive, setIsDragOverActive] = useState(false);
  const [mediaPage, setMediaPage] = useState(1);
  const [mediaLimit, setMediaLimit] = useState(20);

  const handleSelectMedia = useCallback(
    (selectedMedia: Media) => {
      if (getMediaItemKind(selectedMedia) === MediaItemKind.folder) {
        setMediaPage(1);
        if (onFolderSelect) {
          onFolderSelect(selectedMedia.id);
        }
      } else {
        updateSelectedMedia(selectedMedia);
      }
    },
    [updateSelectedMedia, onFolderSelect],
  );

  const handleSelectApp = useCallback((selectedApp: Gridapp) => {
    updateSelectedApp(selectedApp);
  }, [updateSelectedApp]);

  const currentFolderId = useMemo(() => {
    return selectedFolderId;
  }, [selectedFolderId]);

  const {
    data: items,
    isLoading: isLoadingItems,
    isFetching: isFetchingItems,
  } = useContentLibraryMediaList(tenantId, mediaPage, mediaLimit, {
    selectedFolderId: currentFolderId,
    ...filters,
  });

  const handleDragOver = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      setIsDragOverActive(true);
    },
    [setIsDragOverActive],
  );

  const handleDragEnd = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOverActive(false);
  }, []);

  const handleDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      setIsDragOverActive(false);

      const files = extractFilesFromDragDrop(event.dataTransfer);
      setNewFiles((prevState) => {
        return [...prevState, ...files];
      });
    },
    [setIsDragOverActive],
  );

  const handleFiltersChange = useCallback(
    (filters: ContentLibraryFilter) => {
      const isAppsContentType = isAppsContentTypeSelected(filters.contentType);
      if (isAppsContentType && onFolderSelect) {
        onFolderSelect(undefined);
        ancestors.current = [];
      }
      setMediaPage(1);
      setFilters(filters);
    },
    [setFilters, onFolderSelect],
  );

  const handleNewFolderUpload = useCallback(() => {
    setIsNewFolderUploadModalVisible(true);
  }, [setIsNewFolderUploadModalVisible]);

  const handleNewFileUpload = useCallback(
    (files: File[]) => {
      setNewFiles((prevState) => {
        return [...prevState, ...files];
      });
    },
    [setNewFiles],
  );

  const handleStockImageUpload = useCallback(() => {
    setIsStockImageUploadVisible(true);
  }, [setIsStockImageUploadVisible]);

  const handleFileStatusChange = useCallback((file, status: ContentUploadMediaStatusEnum) => {
    if (status === ContentUploadMediaStatusEnum.DONE) {
      setNewFiles((prevState) => {
        return prevState.filter((f) => f.name !== file.name);
      });
    }
  }, []);

  const handleFolderHierarchyClick = useCallback(
    (folderId: string) => {
      setMediaPage(1);
      if (onFolderSelect) {
        onFolderSelect(folderId);
      }
    },
    [onFolderSelect],
  );

  const handlePageChange = useCallback((page: number) => {
    setMediaPage(page);
  }, [setMediaPage]);

  const handlePageSizeChange = useCallback((_: number, pageSize: number) => {
    setMediaPage(1);
    setMediaLimit(pageSize);
  }, [setMediaPage, setMediaLimit]);

  const handleAppSettingsUpdated = useCallback((gridApp: Gridapp, gridAppBuild: GridappBuild, appSettingsData: Record<string, string | number | boolean | Record<string, string>>) => {
    if (onAppSelect) {
      onAppSelect(gridApp, gridAppBuild, appSettingsData);
    }

    updateSelectedApp(undefined);
  }, [onAppSelect]);

  const isAppsItems = useMemo(() => {
    return isAppsContentTypeSelected(filters.contentType);
  }, [filters])

  const ancestors = useRef<Media[]>([]);
  if (items && 'media' in items.library && !isLoadingItems && !isFetchingItems) {
    ancestors.current = items.library.ancestors;
  }

  return (
    <DragDrop
      style={isDragOverActive ? { opacity: 0.4 } : {}}
      onDragOver={handleDragOver}
      onDragEnter={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragLeave={handleDragEnd}
      onDrop={handleDrop}
    >
      <Container>
        <ContentLibraryFilters
          filters={filters}
          onFiltersChange={handleFiltersChange}
          onNewFolderUpload={handleNewFolderUpload}
          onNewFileUpload={handleNewFileUpload}
          onStockImageUpload={handleStockImageUpload}
        />

        {newFiles.length > 0 &&
          newFiles.map((file: File, index) => {
            return (
              <div key={index}>
                <ContentLibraryUploadFile
                  tenantId={tenantId}
                  file={file}
                  parentFolderId={currentFolderId}
                  onFileStatusChange={handleFileStatusChange}
                />
              </div>
            );
          })}

        <ContentLIbraryFolderHierarchyContainer>
          {ancestors.current.length > 0 && (
            <ContentLibraryFolderHierarchy
              tenantId={tenantId}
              ancestors={ancestors.current}
              onFolderHierarchyClick={handleFolderHierarchyClick}
            />
          )}
        </ContentLIbraryFolderHierarchyContainer>

        {!isAppsItems && (
          <ContentLibraryMediaList
            key='content-library-media-list'
            tenantId={tenantId}
            items={items && items.library && 'media' in items.library ? items.library.media : EMPTY_MEDIA_PAGINATION_COLLECTION}
            isLoadingItems={isLoadingItems || isFetchingItems}
            onSelectMedia={handleSelectMedia}
            pagination={{
              page: mediaPage,
              limit: mediaLimit,
              total: items && items.library && 'media' in items.library ? items.library.media.totalDocs : 0,
              onPageChange: handlePageChange,
              onPageSizeChange: handlePageSizeChange,
            }}
          />
        )}

        {isAppsItems && (
          <ContentLibraryAppList
            key='content-library-app-list'
            apps={items && items.library && 'apps' in items.library ? items.library.apps : EMPTY_APPS_PAGINATION_COLLECTION}
            isLoadingApps={isLoadingItems || isFetchingItems}
            pagination={{
              page: mediaPage,
              limit: mediaLimit,
              total: items && items.library && 'apps' in items.library ? items.library.apps.totalDocs : 0,
              onPageChange: handlePageChange,
              onPageSizeChange: handlePageSizeChange,
            }}
            onSelectApp={handleSelectApp}
          />
        )}

        {selectedMedia && (
          <MediaPreviewModalContainer>
            <ModalFixedHeight>
              <Modal
                okText={mediaSelectText || t('ok')}
                title={titleForPreviewModal}
                visible={selectedMedia ? true : false}
                onCancel={() => updateSelectedMedia(undefined)}
                cancelText={t('close')}
                okButtonProps={!mediaSelectText ? { hidden: true } : {}}
                onOk={() => {
                  if (onMediaSelect) {
                    onMediaSelect(selectedMedia);
                  }

                  updateSelectedMedia(undefined);
                }}
                style={{ float: 'right', position: 'absolute', top: 0, right: 0 }}
                maskStyle={{ backgroundColor: 'rgba(37, 42, 61, 0.2)' }}
              >
                <ContentLibraryMediaPreview mediaItem={selectedMedia} />
              </Modal>
            </ModalFixedHeight>
          </MediaPreviewModalContainer>
        )}

        {selectedApp && (
          <ModalFixedHeight>
            <Modal
              title={selectedApp.displayName}
              visible={selectedApp ? true : false}
              onCancel={() => updateSelectedApp(undefined)}
              style={{ float: 'right', position: 'absolute', top: 0, right: 0 }}
              maskStyle={{ backgroundColor: 'rgba(37, 42, 61, 0.2)' }}
              okText={t('contents.saveAllChanges')}
              cancelText={t('contents.close')}
              okButtonProps={{ htmlType: 'submit', form: 'content-library-app-preview-schema-form', hidden: disableAppSettingsForm }}
            >
              <ContentLibraryAppPreview
                tenantId={tenantId}
                app={selectedApp}
                disableAppSettingsForm={disableAppSettingsForm}
                onAppSettingsUpdated={handleAppSettingsUpdated}
              />
            </Modal>
          </ModalFixedHeight>
        )}

        {isNewFolderUploadModalVisible && (
          <ContentLibraryUploadFolder
            tenantId={tenantId}
            parentFolderId={currentFolderId}
            isVisible={isNewFolderUploadModalVisible}
            onCloseModal={() => {
              setIsNewFolderUploadModalVisible(false);
            }}
          />
        )}

        {isStockImageUploadVisible && (
          <ContentLibraryFreeImage
            tenantId={tenantId}
            parentFolderId={currentFolderId}
            isVisible={isStockImageUploadVisible}
            onClose={() => {
              setIsStockImageUploadVisible(false);
            }}
          />
        )}
      </Container>
    </DragDrop>
  );
};

export default ContentLibrary;

const Container = styled.div`
  width: 100%;
  padding: 30px 80px 40px;
`;

const MediaPreviewModalContainer = styled.div``;

const ContentLIbraryFolderHierarchyContainer = styled.div`
  margin-top: 10px;
` as ButtonType;

const DragDrop = styled.div``;
