import { useMemo } from 'react';
import { useStore } from 'easy-peasy';
import { RootState } from '../../models/root.model';
import OrganisationSpace from '../../types/organisation-space';

export interface SpacesAccessInterface {
  hasAccessToAllSpaces?: boolean | undefined;
  spacesAccess?: OrganisationSpace[] | string[];
}

interface OrganisationWithRole extends SpacesAccessInterface {
  organizationId: string;
  roleId: string;
}

const useUserSpacePermissions = (tenantId: string): SpacesAccessInterface => {

  const { user } = useStore<RootState>(
    (state) => ({
      user: state.auth.user,
    }),
    [tenantId],
  );

  return useMemo(() => {
    if (user) {
      if (user.isSysadmin) {
        return {
          hasAccessToAllSpaces: true,
          spacesAccess: []
        };
      }

      const tenantRole = user.organizationsWithRole.find(
        (orgWithRole: OrganisationWithRole) => orgWithRole.organizationId === tenantId
      );

      if (tenantRole) {
        return {
          hasAccessToAllSpaces: !!tenantRole.hasAccessToAllSpaces,
          spacesAccess: tenantRole.spacesAccess
        };
      }

      // check in last to handle the case where when creating the user, no tenant is assigned
      // the user will have isSales true by default
      if (user.isSales) {
        return {
          hasAccessToAllSpaces: true,
          spacesAccess: []
        };
      }
    }

    return {
      hasAccessToAllSpaces: false,
      spacesAccess: []
    };
  }, [user, tenantId]);
};

export default useUserSpacePermissions;
