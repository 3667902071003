import {
  UseOrganizationUsersCreateFormFieldsProps,
} from '../store/hooks/organization-users/use-organization-users-create';
import {
  ScopeLevelPickerSpaceValues,
  UseOrganizationUsersEditFormFieldsProps,
} from '../store/hooks/organization-users/use-organization-users-edit';
import { isProd } from './env';
import { SpacesAccessInterface } from '../store/hooks/spaces/useUserSpacePermissions';

type UserFormFieldsProps = UseOrganizationUsersCreateFormFieldsProps | UseOrganizationUsersEditFormFieldsProps;

export const formatAllowedUserSpacesData = <T extends UserFormFieldsProps>(formData: T, requestorUserSpaceAccess: SpacesAccessInterface): T => {
  const { allowedSpaceIds, ...rest } = formData;
  const spaceArray = allowedSpaceIds as ScopeLevelPickerSpaceValues[];
  const isSingleSpace = spaceArray.length === 1 && spaceArray[0].id === spaceArray[0].type;
  let hasAccessToAllSpaces = isProd() ? true : isSingleSpace;
  const spaceIds = spaceArray.map(space => space.id);
  let updatedAllowedSpaceIds = hasAccessToAllSpaces ? [] : spaceIds;

  // if requester user do not have access to all spaces, hasAccessToAllSpaces for the user who is being edited or created must be false
  // and managed space access (same as requester user) should be given
  if(hasAccessToAllSpaces && !requestorUserSpaceAccess.hasAccessToAllSpaces){
    hasAccessToAllSpaces = false;
    updatedAllowedSpaceIds = requestorUserSpaceAccess.spacesAccess as string[];
  }

  return {
    ...rest,
    hasAccessToAllSpaces,
    allowedSpaceIds: updatedAllowedSpaceIds,
  } as T;
};