/* eslint-disable no-underscore-dangle */
import React, { useEffect, useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import deepCopy from 'lodash/cloneDeep';
import get from 'lodash/get';
import set from 'lodash/set';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Col, Row, Typography, message, Alert } from 'antd';
import PanelCard from '../../../../../common/panel-card/panel-card.component';
import SettingsConfiguration from './settings-configuration.component';
import ConfigType from '../../../../../../store/types/settings-configuration';
import { useApp, useUpdateAndCreateRelease } from '../../../../../common/use-app';
import { useSpace } from '../../../../../common/use-spaces';
import useDevice from '../../../../../common/use-devices';
import Spinner from '../../../../../common/spinner/spinner.component';
import Overlay from '../../../../../common/overlay/overlay.component';
import OrganisationApp from '../../../../../../store/types/organisation-app';
import useAppSchema from '../../../../../common/use-app-schema';
import useAppMetaSchema from '../../../../../common/use-app-meta-schema';
import SettingsForm, { useSettingsForm } from './settings-form.component';
import DeviceTypeEnum from '../../../../../../store/types/device-type.enum';
import InstallationGroup from '../../../../../../store/types/installation-group';
import {
  getAllLocaleCodes,
  getLocaleCodeLabel,
} from '../../../../../../utils/language/language.util';
import DropDownWithCreate from '../../../../../common/schema-form/internal-widgets/dropdown-with-create/dropdown-with-create.component';
import MultiSelect from '../../../../../common/schema-form/widgets/multi-select/multi-select.component';
import { buildDiff, removeAdditionalPropertiesInAppSettings } from './settings.utils';
import useOverriddenSettings from './use-overridden-settings';
import useCreateInstallationGroupCallback from '../../../../../common/use-create-installation-group-callback';
import { useInstallationGroups } from '../../../../../common/use-installation-groups';
import { InstallationSettingsLevel } from '../../../../../../store/types/organisation-app';
import useSpaceSettingsFromApp from './use-space-settings';
import {
  SpaceIcon,
  DeviceIcon,
  GlobalIcon,
} from '../../../../../common/settings-overriding/settings-overriding-icons.component';
import Environment from '../../../../../../store/types/environment';
import { useEnvironmentsV1 } from '../../../../../common/use-environments';
import LanguageSwitcher from '../../../../../common/language-switcher/language-switcher.component';
import { permissionKeys } from '../../../../../../utils/auth/permissions';
import useHasPermissions from '../../../../../../utils/auth/use-has-permissions';
import { useGridAppBuilds } from '../../../../../common/use-grid-app-builds';

const { Title } = Typography;

const ErrorMessage = styled.div`
  padding: 15px 0;
  color: rgb(255, 85, 0);
`;

const StickyColumn = styled(Col)`
  position: sticky;
  top: 64px;
  margin-top: 16px;
  @media screen and (min-width: 1200px) {
    margin-top: 0;
  }
`;
const TitleStyled = styled(Title)`
  margin-bottom: 10px !important;
`;

const SettingOptionsDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100px;
  margin-top: 24px;
`;

const SettingsDescription = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
  line-height: 1.2;
`;

const SpaceIconWrapper = styled.div`
  border-radius: 4px;
  background-color: rgba(228, 12, 134, 0.16);
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 9px;
  padding-right: 6px;
  margin-right: 8px;
`;

const DeviceIconWrapper = styled(SpaceIconWrapper)`
  background-color: rgba(95, 6, 228, 0.06);
`;

const GlobalIconWrapper = styled(SpaceIconWrapper)`
  background-color: rgba(0, 0, 0, 0.1);
`;

const StyledSettingsRow = styled(Row)`
  width: 100%;
  padding: 0;
  margin: auto 0;

  @media screen and (min-width: 1300px) {
    max-width: 1280px;
    margin: -16px 0 auto 0;
  }
`;

const SettingsOptionsLabelPanelCard = styled(PanelCard)`
  margin-bottom: 16px;
  padding: 16px 16px 8px 16px;
`;

const PanelCardStyled = styled(PanelCard)`
  padding: 0 16px;
`;

const LanguageSwitcherWrapper = styled.div`
  display: flex;
  z-index: 999;
  width: 100%;
  flex-direction: column;
`;

const LanguageSwitcherTitle = styled.p`
  margin-bottom: 8px;
`;

const LanguageSelect = styled(LanguageSwitcher)`
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledAlert = styled(Alert)`
  margin-bottom: 16px;
`;

interface StickyContainerProps {
  isMultiLanguageSupport: boolean;
  languageFrom: string;
  onChangeLanguageFrom: (language: string) => void;
  languageTo: string;
  onChangeLanguageTo: (language: string) => void;
  supportedLanguages: string[];
  organisationId: string;
}

const StickyContainer: React.FC<StickyContainerProps> = ({
  isMultiLanguageSupport,
  languageFrom,
  onChangeLanguageFrom,
  languageTo,
  onChangeLanguageTo,
  supportedLanguages = [],
  organisationId,
}) => {
  const { t } = useTranslation();

  const isEditAllowed = useHasPermissions(organisationId, permissionKeys.apps.update);

  return (
    <StickyColumn md={24} xl={8}>
      {!isEditAllowed && (
        <StyledAlert
          message={t('globalSettingsForm.viewMode')}
          description={t('globalSettingsForm.viewModeDescription')}
          type="warning"
          showIcon
        />
      )}
      <SettingsOptionsLabelPanelCard>
        <TitleStyled level={4}>{t('globalSettingsForm.settingsLegend')}</TitleStyled>
        <SettingOptionsDescriptionContainer>
          <SettingsDescription>
            <GlobalIconWrapper>
              <GlobalIcon />
            </GlobalIconWrapper>
            {t('globalSettingsForm.globalInherit')}
          </SettingsDescription>
          <SettingsDescription>
            <SpaceIconWrapper>
              <SpaceIcon />
            </SpaceIconWrapper>
            {t('globalSettingsForm.spaceInherit')}
          </SettingsDescription>
          <SettingsDescription>
            <DeviceIconWrapper>
              <DeviceIcon />
            </DeviceIconWrapper>
            {t('globalSettingsForm.deviceValue')}
          </SettingsDescription>
        </SettingOptionsDescriptionContainer>
      </SettingsOptionsLabelPanelCard>

      {isMultiLanguageSupport && (
        <SettingsOptionsLabelPanelCard>
          <LanguageSwitcherWrapper>
            <LanguageSwitcherTitle>{t('languageFrom')}</LanguageSwitcherTitle>

            <LanguageSelect
              fullLabel
              value={languageFrom}
              options={supportedLanguages || []}
              onChange={onChangeLanguageFrom}
            />

            <LanguageSwitcherTitle>{t('languageTo')}</LanguageSwitcherTitle>

            <LanguageSelect
              fullLabel
              value={languageTo}
              options={supportedLanguages || []}
              onChange={onChangeLanguageTo}
            />
          </LanguageSwitcherWrapper>
        </SettingsOptionsLabelPanelCard>
      )}
    </StickyColumn>
  );
};

interface UseAppLanguageResult {
  defaultLanguage: string;
  supportedLanguages: string[];
  languageFrom: string;
  onChangeLanguageFrom: (language: string) => void;
  languageTo: string;
  onChangeLanguageTo: (language: string) => void;
}

const useAppLanguage = (app: OrganisationApp): UseAppLanguageResult => {
  const [languageFrom, setLanguageFrom] = useState('');
  const [languageTo, setLanguageTo] = useState('');

  const { supportedLanguages, defaultLanguage } = useMemo(() => {
    return {
      supportedLanguages: app.multiLanguageSupport ? app.supportedLanguages || [] : [],
      defaultLanguage: app.multiLanguageSupport ? app.defaultLanguage || '' : '',
    };
  }, [app]);

  useEffect(() => {
    setLanguageFrom(defaultLanguage);

    const otherLanguage = supportedLanguages.find((lang) => lang !== defaultLanguage);

    setLanguageTo(otherLanguage || defaultLanguage);
  }, [defaultLanguage, supportedLanguages]);

  const result = useMemo(
    () => ({
      defaultLanguage,
      supportedLanguages,
      languageFrom,
      onChangeLanguageFrom: setLanguageFrom,
      languageTo,
      onChangeLanguageTo: setLanguageTo,
    }),
    [defaultLanguage, supportedLanguages, languageFrom, languageTo],
  );

  return result;
};

interface BaseSettingsProps {
  app: OrganisationApp;
  schema: any;
  metaSchema: any;
  organisationId: string;
  environments: Environment[];
}

interface GlobalBaseSettingsProps {
  app: OrganisationApp;
  schema: any;
  metaSchema: any;
  organisationId: string;
  installationGroups?: InstallationGroup[];
  createInstallationGroup: (
    params: Partial<InstallationGroup>,
  ) => Promise<string | undefined>;
  environments: Environment[];
}

const getInitialAppSettings = (app: OrganisationApp) => {
  return {
    ...app.settings,
    _general: {
      displayName: app.displayName,
      installationGroupId: app.installationGroupId || '',
      multiLanguageSupport: app.multiLanguageSupport,
      release: app.release,
      sku: app.sku || '',
      supportedLanguages: app.supportedLanguages,
      defaultLanguage: app.defaultLanguage,
    },
  };
};

const getGridAppSettingsFromApp = (app: OrganisationApp) => {
  return get(app, ['provider', 'app', 'gridApp', 'settings']);
};

const parseSettingsOnSave = (app: OrganisationApp, settings: any) => {
  const newSettings = deepCopy(settings);
  const generalSettings = newSettings._general;

  const newApp = {
    ...app,
    displayName: generalSettings.displayName,
    installationGroupId: generalSettings.installationGroupId,
    multiLanguageSupport: generalSettings.multiLanguageSupport,
    release: generalSettings.release,
    sku: generalSettings.sku || '',
    supportedLanguages: generalSettings.supportedLanguages,
    defaultLanguage: generalSettings.defaultLanguage,
  };

  delete newSettings._general;

  return {
    ...newApp,
    settings: newSettings,
  };
};

const useInitialGlobaSettings = (app: OrganisationApp) => {
  const [initialSettings, setInitialSettings] = useState<any>({});

  useEffect(() => {
    setInitialSettings(getInitialAppSettings(app));
  }, [app]);

  return initialSettings;
};

const GlobalSettings: React.FC<GlobalBaseSettingsProps> = ({
  app,
  organisationId,
  schema,
  metaSchema,
  installationGroups = [],
  createInstallationGroup,
  environments,
}) => {
  const initialSettings = useInitialGlobaSettings(app);

  const { settings, onChangeSettings, isFormPristine } = useSettingsForm(initialSettings);

  const localeCodeList = useMemo(() => getAllLocaleCodes(), []);

  const gridAppId = useMemo(() => get(app, 'settings.provider.app.gridApp.gridapp.id', ''), [app]);

  const { data: gridAppBuilds = [] } = useGridAppBuilds({ gridAppId });

  const {
    languageFrom,
    languageTo,
    onChangeLanguageFrom,
    onChangeLanguageTo,
    defaultLanguage,
    supportedLanguages,
  } = useAppLanguage(app);

  const { isCloudApp, isIoTApp } = useMemo(() => {
    return {
      isCloudApp: !!app && app.deviceType === DeviceTypeEnum.CLOUD,
      isIoTApp: !!app && app.deviceType === DeviceTypeEnum.IOT,
    };
  }, [app]);
  const { t } = useTranslation();

  const title = t('globalSettingsForm.globalSettings');

  const subTitle = app.displayName || '';

  const icon = 'global';

  const updateAppAndCreateRelease = useUpdateAndCreateRelease(app.id);

  const onSave = useCallback(
    async (autoDeployEnvironment: string) => {
      const newApp = parseSettingsOnSave(app, settings);

      const buildId = get(newApp, 'settings.provider.app.gridApp.gridapp.buildId', '');

      // Enable layout v2 for IoT installations initially
      if (buildId && app.deviceType === DeviceTypeEnum.IOT) {
        const matchedBuild = gridAppBuilds.find((build) => build.id === buildId);

        if (matchedBuild && matchedBuild.result) {
          const settingsSchema = matchedBuild.result.settingsSchema;
          const appWithoutAdditionalSettingsProperties = removeAdditionalPropertiesInAppSettings(newApp, settingsSchema);
          updateAppAndCreateRelease.mutate({
            ...appWithoutAdditionalSettingsProperties,
            autoDeployEnvironment
          });

          return;
        }
      }

      updateAppAndCreateRelease.mutate({ ...newApp, autoDeployEnvironment });
    },
    [app, updateAppAndCreateRelease, settings, gridAppBuilds],
  );

  useEffect(() => {
    if (updateAppAndCreateRelease.isSuccess) {
      message.success(t('installationSuccessfullyUpdated'));
    }
  }, [t, updateAppAndCreateRelease.isSuccess]);

  const errorMessage = updateAppAndCreateRelease.isError
    ? t('errorSavingContent')
    : undefined;

  const formSchema = useMemo(() => {
    const hasSchema = Object.values(schema.properties.provider.properties).length > 0;

    const skuField = {
      sku: {
        type: 'string',
        title: 'SKU',
        default: app.sku || '',
      },
    };

    return {
      ...schema,
      ...(hasSchema
        ? {
            properties: {
              _general: {
                type: 'object',
                title: ' ',
                properties: {
                  ...(!isCloudApp
                    ? {
                        displayName: {
                          type: 'string',
                          title: t('installationName'),
                          default: app.displayName,
                        },
                      }
                    : {}),
                  installationGroupId: {
                    type: 'string',
                    title: t('addToGroup'),
                    enum: ['', ...installationGroups.map((group) => group.id)],
                    enumNames: [
                      t('myInstallationsDefault'),
                      ...installationGroups.map((group) => group.displayName),
                    ],
                    default: app.installationGroupId || '',
                  },
                  ...(!isCloudApp && !isIoTApp
                    ? {
                        multiLanguageSupport: {
                          type: 'boolean',
                          title: t('multiLanguageSupport'),
                          default: app.multiLanguageSupport,
                        },
                      }
                    : {}),
                },
                dependencies: {
                  multiLanguageSupport: {
                    oneOf: [
                      {
                        properties: {
                          multiLanguageSupport: {
                            enum: [true],
                          },
                          supportedLanguages: {
                            title: t('supportedLanguages'),
                            type: 'array',
                            uniqueItems: true,
                            minItems: 1,
                            items: {
                              type: 'string',
                              enum: localeCodeList.map((localeCode) => localeCode.key),
                              enumNames: localeCodeList.map(
                                (localeCode) => localeCode.label,
                              ),
                            },
                          },
                          ...(supportedLanguages.length
                            ? {
                                defaultLanguage: {
                                  type: 'string',
                                  title: t('defaultLanguage'),
                                  enum: supportedLanguages,
                                  enumNames: supportedLanguages.map((code) =>
                                    getLocaleCodeLabel(code),
                                  ),
                                },
                              }
                            : {}),
                          ...skuField,
                        },
                      },
                      {
                        properties: {
                          multiLanguageSupport: {
                            enum: [false],
                          },
                          ...skuField,
                        },
                      },
                    ],
                  },
                },
              },
              ...schema.properties,
            },
          }
        : {}),
    };
  }, [
    schema,
    t,
    isCloudApp,
    app,
    installationGroups,
    isIoTApp,
    localeCodeList,
    supportedLanguages,
  ]);

  const updatedMetaSchema = useMemo(
    () => ({
      ...metaSchema,
      _general: {
        supportedLanguages: {
          'ui:field': MultiSelect,
        },
        installationGroupId: {
          'ui:field': (fromProps: any) => (
            <DropDownWithCreate {...fromProps} onCreate={createInstallationGroup} />
          ),
          sku: {
            roles: ['sysadmin'],
          },
        },
      },
    }),
    [createInstallationGroup, metaSchema],
  );

  const languageOptions = useMemo(
    () => ({
      defaultLanguage,
      supportedLanguages,
      languageFrom,
      languageTo,
    }),
    [defaultLanguage, languageFrom, languageTo, supportedLanguages],
  );

  return (
    <SettingsConfiguration
      title={title}
      subTitle={subTitle}
      icon={icon}
      app={app}
      organisationId={organisationId}
      onSave={onSave}
      isSaving={updateAppAndCreateRelease.isLoading}
      isSaveButtonDisabled={isFormPristine}
      errorMessage={errorMessage}
      environments={environments}
    >
      <StyledSettingsRow gutter={40}>
        <Col md={24} xl={16}>
          <PanelCardStyled>
            <SettingsForm
              organisationId={organisationId}
              metaSchema={updatedMetaSchema}
              schema={formSchema}
              data={settings}
              app={app}
              onChange={onChangeSettings}
              showVersionPicker
              settingsLevel={InstallationSettingsLevel.GLOBAL}
              languageOptions={languageOptions}
            />
          </PanelCardStyled>
        </Col>

        <StickyContainer
          isMultiLanguageSupport={app.multiLanguageSupport}
          languageFrom={languageFrom}
          onChangeLanguageFrom={onChangeLanguageFrom}
          languageTo={languageTo}
          onChangeLanguageTo={onChangeLanguageTo}
          supportedLanguages={supportedLanguages}
          organisationId={organisationId}
        />
      </StyledSettingsRow>
    </SettingsConfiguration>
  );
};

interface SpaceSettingsProps extends BaseSettingsProps {
  spaceId: string;
}

const SpaceSettings: React.FC<SpaceSettingsProps> = ({
  app,
  organisationId,
  spaceId,
  schema,
  metaSchema,
  environments,
}) => {
  const { t } = useTranslation();

  const spaceState = useSpace(spaceId, organisationId);

  const updateAppAndCreateRelease = useUpdateAndCreateRelease(app.id);

  const settingsDiff = useMemo(() => {
    const { spaceSettingsOverriding } = app;

    if (!spaceSettingsOverriding) {
      return [];
    }

    const result = spaceSettingsOverriding[spaceId] || [];

    return result;
  }, [app, spaceId]);

  const initialSettings = useOverriddenSettings({
    appSettings: app.settings,
    settingsDiff,
  });

  const globalSettings = useInitialGlobaSettings(app);
  const globalSettingsContext = useMemo(() => getGridAppSettingsFromApp(globalSettings), [
    globalSettings,
  ]);

  const { settings, onChangeSettings, isFormPristine } = useSettingsForm(initialSettings);

  const title = t('spaceForm.spaceSettings');

  const icon = 'environment';

  const errorMessage = updateAppAndCreateRelease.isError
    ? t('errorSavingContent')
    : undefined;

  const onSave = useCallback(
    async (autoDeployEnvironment: string) => {
      const newSettingsDiff = buildDiff(app.settings, settings);

      const { spaceSettingsOverriding = {} } = app;

      const newApp = {
        ...app,
        spaceSettingsOverriding: {
          ...spaceSettingsOverriding,
          [spaceId]: newSettingsDiff,
        },
      };

      updateAppAndCreateRelease.mutate({ ...newApp, autoDeployEnvironment });
    },
    [app, updateAppAndCreateRelease, spaceId, settings],
  );

  useEffect(() => {
    if (updateAppAndCreateRelease.isSuccess) {
      message.success(t('installationSuccessfullyUpdated'));
    }
  }, [t, updateAppAndCreateRelease.isSuccess]);

  const {
    languageFrom,
    languageTo,
    onChangeLanguageFrom,
    onChangeLanguageTo,
    defaultLanguage,
    supportedLanguages,
  } = useAppLanguage(app);

  const languageOptions = useMemo(
    () => ({
      defaultLanguage,
      supportedLanguages,
      languageFrom,
      languageTo,
    }),
    [defaultLanguage, languageFrom, languageTo, supportedLanguages],
  );

  set(metaSchema, 'provider.app.gridApp["ui:options"].isVisible', false);

  return (
    <>
      {spaceState.isLoading && (
        <Overlay>
          <Spinner />
        </Overlay>
      )}

      {spaceState.isSuccess && (
        <SettingsConfiguration
          title={title}
          subTitle={
            spaceState.data.displayName ||
            `${t('settingsConfigurationForm.unknown')} ${t('space')}`
          }
          icon={icon}
          app={app}
          organisationId={organisationId}
          onSave={onSave}
          isSaving={updateAppAndCreateRelease.isLoading}
          isSaveButtonDisabled={isFormPristine}
          errorMessage={errorMessage}
          environments={environments}
        >
          <StyledSettingsRow gutter={40}>
            <Col md={24} xl={16}>
              <PanelCardStyled>
                <SettingsForm
                  organisationId={organisationId}
                  metaSchema={metaSchema}
                  schema={schema}
                  data={settings}
                  app={app}
                  onChange={onChangeSettings}
                  showSettingsOverridingIndicator
                  globalSettingsContext={globalSettingsContext}
                  settingsLevel={InstallationSettingsLevel.SPACE}
                  languageOptions={languageOptions}
                  showVersionPicker
                />
              </PanelCardStyled>
            </Col>

            <StickyContainer
              isMultiLanguageSupport={app.multiLanguageSupport}
              languageFrom={languageFrom}
              onChangeLanguageFrom={onChangeLanguageFrom}
              languageTo={languageTo}
              onChangeLanguageTo={onChangeLanguageTo}
              supportedLanguages={supportedLanguages}
              organisationId={organisationId}
            />
          </StyledSettingsRow>
        </SettingsConfiguration>
      )}

      {spaceState.isError && (
        <ErrorMessage>{t('errorDuringFetchingSpaces')}</ErrorMessage>
      )}
    </>
  );
};

interface DeviceSettingsProps extends BaseSettingsProps {
  deviceId: string;
}

const DeviceSettings: React.FC<DeviceSettingsProps> = ({
  app,
  deviceId,
  organisationId,
  metaSchema,
  schema,
  environments,
}) => {
  const { t } = useTranslation();

  const deviceState = useDevice(deviceId);

  const title = `${t('device')} ${t('settings')}`;

  const subTitle = deviceState.data
    ? deviceState.data.deviceName
    : `${t('settingsConfigurationForm.unknown')} ${t('device')}`;

  const icon = 'hardDrive';

  const updateAppAndCreateRelease = useUpdateAndCreateRelease(app.id);

  const settingsDiff = useMemo(() => {
    const { deviceSettingsOverriding } = app;

    if (!deviceSettingsOverriding) {
      return [];
    }

    const result = deviceSettingsOverriding[deviceId] || [];

    return result;
  }, [app, deviceId]);

  const spaceId = deviceState.data ? deviceState.data.spaces[0] || '' : '';

  const { settings: spaceSettings } = useSpaceSettingsFromApp({
    app,
    spaceId,
  });

  const initialSettings = useOverriddenSettings({
    appSettings: spaceSettings ? spaceSettings : {},
    settingsDiff,
  });

  const { settings, onChangeSettings, isFormPristine } = useSettingsForm(initialSettings);

  const globalSettings = useInitialGlobaSettings(app);

  const globalSettingsContext = useMemo(() => getGridAppSettingsFromApp(globalSettings), [
    globalSettings,
  ]);
  const spaceSettingsContext = useMemo(() => getGridAppSettingsFromApp(spaceSettings), [
    spaceSettings,
  ]);

  const onSave = useCallback(
    async (autoDeployEnvironment: string) => {
      const newSettingsDiff = buildDiff(spaceSettings, settings);

      const { deviceSettingsOverriding = {} } = app;

      const newApp = {
        ...app,
        deviceSettingsOverriding: {
          ...deviceSettingsOverriding,
          [deviceId]: newSettingsDiff,
        },
      };

      updateAppAndCreateRelease.mutate({ ...newApp, autoDeployEnvironment });
    },
    [app, updateAppAndCreateRelease, deviceId, settings, spaceSettings],
  );

  useEffect(() => {
    if (updateAppAndCreateRelease.isSuccess) {
      message.success(t('installationSuccessfullyUpdated'));
    }
  }, [t, updateAppAndCreateRelease.isSuccess]);

  const {
    languageFrom,
    languageTo,
    onChangeLanguageFrom,
    onChangeLanguageTo,
    defaultLanguage,
    supportedLanguages,
  } = useAppLanguage(app);

  const languageOptions = useMemo(
    () => ({
      defaultLanguage,
      supportedLanguages,
      languageFrom,
      languageTo,
    }),
    [defaultLanguage, languageFrom, languageTo, supportedLanguages],
  );
  
  set(metaSchema, 'provider.app.gridApp["ui:options"].isVisible', false);

  return (
    <>
      {deviceState.isLoading && (
        <Overlay>
          <Spinner />
        </Overlay>
      )}

      {deviceState.isSuccess && (
        <SettingsConfiguration
          title={title}
          subTitle={subTitle}
          icon={icon}
          app={app}
          organisationId={organisationId}
          onSave={onSave}
          isSaving={updateAppAndCreateRelease.isLoading}
          isSaveButtonDisabled={isFormPristine}
          environments={environments}
        >
          <StyledSettingsRow gutter={40}>
            <Col md={24} xl={16}>
              <PanelCardStyled>
                <SettingsForm
                  organisationId={organisationId}
                  metaSchema={metaSchema}
                  schema={schema}
                  data={settings}
                  app={app}
                  onChange={onChangeSettings}
                  globalSettingsContext={globalSettingsContext}
                  spaceSettingsContext={spaceSettingsContext}
                  showSettingsOverridingIndicator
                  settingsLevel={InstallationSettingsLevel.DEVICE}
                  languageOptions={languageOptions}
                  showVersionPicker
                />
              </PanelCardStyled>
            </Col>

            <StickyContainer
              isMultiLanguageSupport={app.multiLanguageSupport}
              languageFrom={languageFrom}
              onChangeLanguageFrom={onChangeLanguageFrom}
              languageTo={languageTo}
              onChangeLanguageTo={onChangeLanguageTo}
              supportedLanguages={supportedLanguages}
              organisationId={organisationId}
            />
          </StyledSettingsRow>
        </SettingsConfiguration>
      )}

      {deviceState.isError && (
        <ErrorMessage>{t('errorDuringFetchingDevice')}</ErrorMessage>
      )}
    </>
  );
};

interface GetComponentProps {
  configType: ConfigType;
  app: OrganisationApp;
  organisationId: string;
  id: string;
  schema: any;
  metaSchema: any;
  installationGroups?: InstallationGroup[];
  createInstallationGroup: (
    params: Partial<InstallationGroup>,
  ) => Promise<string | undefined>;
  environments: Environment[];
}

const getComponent = ({
  configType,
  app,
  organisationId,
  id,
  schema,
  metaSchema,
  installationGroups,
  createInstallationGroup,
  environments,
}: GetComponentProps) => {
  switch (configType) {
    case ConfigType.GLOBALSETTINGS:
      return (
        <GlobalSettings
          app={app}
          schema={schema}
          metaSchema={metaSchema}
          organisationId={organisationId}
          installationGroups={installationGroups}
          createInstallationGroup={createInstallationGroup}
          environments={environments}
        />
      );

    case ConfigType.SPACE:
      return (
        <SpaceSettings
          app={app}
          schema={schema}
          metaSchema={metaSchema}
          organisationId={organisationId}
          spaceId={id}
          environments={environments}
        />
      );

    case ConfigType.DEVICE:
      return (
        <DeviceSettings
          app={app}
          schema={schema}
          metaSchema={metaSchema}
          deviceId={id}
          organisationId={organisationId}
          environments={environments}
        />
      );

    default:
      throw new Error(`Unknown config type: ${configType}`);
  }
};

interface DataContainerChildrenProps {
  app: OrganisationApp;
  schema: any;
  metaSchema: any;
}

interface DataContainerProps {
  appId: string;
  children: (props: DataContainerChildrenProps) => void;
}

const DataContainer: React.FC<DataContainerProps> = ({ appId, children }) => {
  const { t } = useTranslation();

  const appState = useApp({ appId });

  const schemaState = useAppSchema({
    release: (appState.data && appState.data.release) || '',
    provider: (appState.data && appState.data.provider) || '',
  });

  const metaSchemaState = useAppMetaSchema({
    release: (appState.data && appState.data.release) || '',
    provider: (appState.data && appState.data.provider) || '',
  });

  return (
    <>
      {(appState.isLoading || schemaState.isLoading || metaSchemaState.isLoading) && (
        <Overlay>
          <Spinner />
        </Overlay>
      )}

      {appState.isSuccess &&
        schemaState.isSuccess &&
        metaSchemaState.isSuccess &&
        children({
          app: appState.data,
          schema: schemaState.data,
          metaSchema: metaSchemaState.data,
        })}

      {(appState.isError || schemaState.isError || metaSchemaState.isError) && (
        <ErrorMessage>{t('errorDuringFetchingApp')}</ErrorMessage>
      )}
    </>
  );
};

type SettingsConfigurationContainerProps = RouteComponentProps<{
  appId: string;
  organisationId: string;
  configType?: ConfigType;
  id?: string;
}>;

const SettingsConfigurationContainer = (props: SettingsConfigurationContainerProps) => {
  const {
    match: {
      params: { configType = ConfigType.GLOBALSETTINGS, appId, organisationId, id = '' },
    },
  } = props;
  const environments = useEnvironmentsV1({ tenantId: organisationId });

  const installationGroupsQuery = useInstallationGroups({ organisationId });
  const installationGroups = installationGroupsQuery.data || [];

  const createInstallationGroup = useCreateInstallationGroupCallback(organisationId);

  return (
    <>
      <DataContainer appId={appId}>
        {({ app, schema, metaSchema }) =>
          getComponent({
            configType,
            app,
            schema,
            metaSchema,
            organisationId,
            id,
            installationGroups,
            createInstallationGroup,
            environments,
          })
        }
      </DataContainer>
    </>
  );
};

export default SettingsConfigurationContainer;
