import { OrientationTagValue } from "../common/content-tags/orientation";
import { SegmentConditions } from "../hooks/segments/types";
import { ChannelTag } from "./channel";

export type SegmentTag = ChannelTag & {
  value: OrientationTagValue | string;
}

export enum SegmentContentTypeEnum {
  MEDIA = 'media',
  APP = 'app',
}

export interface SegmentMediaContent {
  type: SegmentContentTypeEnum.MEDIA,
  mediaId: string | null;
}

export interface SegmentAppContent {
  type: SegmentContentTypeEnum.APP,
  gridAppId: string | null,
  gridAppBuildId: string | null,
  appSettingsData: Record<string, string | number | boolean | Record<string, string>>,
}

export type SegmentContent = SegmentMediaContent | SegmentAppContent;

export interface SegmentTagsContent {
  content: SegmentMediaContent | SegmentAppContent | undefined;
  tags: SegmentTag[];
}

export interface Segment {
  id?: string;
  name: string;
  startDate: string;
  endDate: string;
  content?: SegmentMediaContent | SegmentAppContent | undefined;
  tagsContent?: SegmentTagsContent[];
  channelId: string;
  conditions: SegmentConditions;
  createdAt: string;
  updatedAt: string;
}